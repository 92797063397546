<template>
    <div class="base">
        <div class="two-fa-main main-body flex-column">
            <div class="text-center">
                <img :src="require('../assets/images/horizontal_logo.png')" width="250" height="50">
            </div> 
            <form @submit.prevent="twoFaLogin">
                <div class="mt-4 text-center">
                    <PincodeInput
                        v-model="twoFaCode"
                        :length=6
                    />
                    <p class="mt-4">{{$t("Key in 2FA code to continue login")}}</p>
                    <button class="security-btn">
                        <span v-if="!isClick">{{$t("Continue")}}</span>
                        <b-spinner v-else label="Spinning"></b-spinner>
                    </button>
                </div>
            </form>

        </div>
    </div>
</template>
<script>
    import PincodeInput from 'vue-pincode-input';
    import api from '../helper/api.js';
     

    export default {
        name: "TwoFa",
        components: {
            PincodeInput
        },
        data(){
            return{
                twoFaCode:"",
                email:this.$route.params.email,
                isClick:false
            }
        },

        async mounted(){
            await this.check2FaCookie()
        },

        methods: {
            async check2FaCookie(){
                try{
                    await api.apiCall("core","GET","/operators/allow2FaLogin");
                    return
                }catch(error){
                    console.log('baka ' + error)
                    this.$swal({
                        text: error.message,
                        icon: 'error'
                    })
                    this.$router.push({name:'Login'});
                }
            },

            async twoFaLogin(){
                this.isClick = true;
                document.querySelector('.security-btn').disabled = true;
                try{
                    let params = {
                        google2fa: this.twoFaCode
                    };
                    await api.apiCall("core","POST","/operators/verify2fa",params);
                    // localStorage.setItem("token",response.message.access_token);

                    await this.getUserType();
                    this.$router.push({name:'Wallet'});
                }catch(error){
                    this.$swal({
                        text: error.message,
                        icon: 'error'
                    })
                    this.twoFaCode = "";
                    this.isClick = false;
                    document.querySelector('.security-btn').disabled = false;
                    console.error(error);
                }
            },

            async getUserType(){
                try{
                    const response = await api.apiCall('core','GET','/operators');
                    console.log(response.message.permission)
                    localStorage.setItem("userType",response.message.service_type);        
                    localStorage.setItem("platform_id",response.message.platform_id);
                    localStorage.setItem("google2fa",response.message.google2fa);
                    localStorage.setItem("platform_name",response.message.platform_name);
                    localStorage.setItem("userEmail",response.message.email);
                    localStorage.setItem('swagger',response.message.swagger);
                    localStorage.setItem("chargeUsd",response.message.charge_usd);
                    // localStorage.setItem("viewOperator",response.message.permission.SETTING || response.message.permission.INVITE);
                    if(response.message.permission.ROOT != undefined){
                        localStorage.setItem('inviteOperator',true);
                        localStorage.setItem('settingOperator',true);
                        localStorage.setItem('root',true);
                        return;
                    }
                    localStorage.setItem('inviteOperator',response.message.permission.INVITE);
                    localStorage.setItem('settingOperator',response.message.permission.SETTING);

                }catch(error){
                    console.error(error);
                }
            }
        },
    }
</script>
<style scoped>
@import '../css/styles.css';

.two-fa-main{
    height: 100vh;
}
</style>